import photo15 from './images/Website 15.jpg';
import photo16 from './images/Website 16.jpg';
import photo17 from './images/Website 17.jpg';
import photo18 from './images/Website 18.jpg';
import photo19 from './images/Website 19.jpg';
import photo20 from './images/Website 20.jpg';
import photo21 from './images/Website 21.jpg';
import photo22 from './images/Website 22.jpg';
import photo23 from './images/Website 23.jpg';
import photo24 from './images/Website 24.jpg';
import photo25 from './images/Website 25.jpg';

import logo from './images/Website Owl Logo.jpg';
import Catalog from './Catalog';
import './App.scss';
import { useState } from 'react';

function App() {
  
  const link15 = "/catalogs/Catalog_15.pdf";
  const link16 = "/catalogs/Catalog_16-All_Things_Nietzsche.pdf";
  const link17 = "/catalogs/Catalog_17-Thirty_Highpoint_in_the_History_of_Ideas.pdf";
  const link18 = "/catalogs/Catalog_18-Women_Philosophers_[1641-1949].pdf";
  const link19 = "/catalogs/Catalog_19-Twenty-Five_German_Philosophers.pdf";
  const link20 = "/catalogs/Catalog_20-Modern_Poetry.pdf";
  const link21 = "/catalogs/Catalog_21-Susanne_K._Langer.pdf";
  const link22 = "/catalogs/Catalog_22-William_James.pdf";
  const link23 = "/catalogs/Catalog_23-20th_Century_Women_Philosophers.pdf";
  const link24 = "/catalogs/Catalog_24-LUDWIG_WITTGENSTEIN_The_Man_and_His_Thought.pdf";
  const link25 = "/catalogs/Catalog_25-CHARLES_SANDERS_PEIRCE.pdf";
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  window.onresize = () => { setWindowWidth(window.innerWidth); console.log(window.innerWidth); }
  const windowMin = 1500;

  return (
    <div className="App">
      <div className='top'>
        <Catalog link={link25} photo={photo25} />

        <div className='tr'>
          <div className='title'>
            <img alt="owl" src={logo} />
            <div className='t-text'>
              <h1><span>A</span>thena <span>R</span>are <span>B</span>ooks</h1>
              <h2>Fine and Rare Books in the History of Ideas</h2>
            </div>
          </div>

          <div className='below-title'>
            <div className='search'>
              <a href="https://www.abebooks.com/servlet/SearchEntry?sortby=1&vci=1286984&refined=1" target="_blank">Search Our Stock</a>
              <p>Click the link above to search</p>
              <p>our current online inventory</p>
            </div>
          
            <div className='info'>
              <p className='addr'>424 Riverside Drive, Fairfield, CT 06824 USA</p>
              <p className='viewing'>(viewing by appointment only)</p>
              <p>203-254-2727 - bill@athenararebooks.com</p>
              <div className='spacer'></div>
              <p className='bold'>William H. Schaberg, Proprietor</p>
              <p className='bold'>Lucy Rose Dasilva, Associate</p>
            </div>
          </div>
        </div>
      </div>

      <div className='catalogs'>
        <Catalog link={link24} photo={photo24} />
        <Catalog link={link23} photo={photo23} />
        <Catalog link={link22} photo={photo22} />
        <Catalog link={link21} photo={photo21} />
        <Catalog link={link20} photo={photo20} />
        <Catalog link={link19} photo={photo19} />
        <Catalog link={link18} photo={photo18} />
        <Catalog link={link17} photo={photo17} />
        <Catalog link={link16} photo={photo16} />
        <Catalog link={link15} photo={photo15} />
      </div>

      <footer>
        <p className='bold'>Copyright 2024. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
