
const Catalog = ({link, photo}) => {
  return (
    <div className="Catalog">
      <a href={link} target="_blank" rel="noopener noreferrer"><img src={photo} alt="catalog"/></a>
    </div>
  );
}

export default Catalog;
